import * as hadron from './hadron-sclang/hadron_sclang.js';
import { Pane } from './pane.js';
export class HelpView extends Pane {
    constructor(hub, container) {
        super(hub, container);
        let div = document.createElement('div');
        div.id = "help";
        div.innerHTML = `
    <h2>hadron ${hadron.get_hadron_version()}
      <a href="https://codeberg.org/hadron/hadron/commit/${hadron.get_hadron_git_sha()}">
        ${hadron.get_hadron_git_branch()}:${hadron.get_hadron_git_sha_abbrev()}
      </a>
    </h2>
    <p>Hadron Help System is not yet implemented, follow
    <a href="https://codeberg.org/hadron/hadron/issues/120">Issue #120</a> for status.</p>`;
        this.container.element.append(div);
        this.init();
    }
    resize() { }
    close() { }
}
