import { GoldenLayout } from 'golden-layout';
import * as monaco from 'monaco-editor';
import * as hadron_language_config from './hadron.js';
import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import { HELP_VIEW_COMPONENT_NAME, EDITOR_COMPONENT_NAME, POST_VIEW_COMPONENT_NAME } from './component.interfaces.js';
import { Hub } from './hub.js';
// css
require('bootstrap/dist/css/bootstrap.min.css');
require('./css/goldenlayout-base.css');
require('./css/goldenlayout-light-theme.css');
require('./css/editor_style.scss');
function start() {
    // Register and configure language with the editor.
    monaco.languages.register({ id: 'hadron' });
    monaco.languages.setLanguageConfiguration('hadron', hadron_language_config.conf);
    monaco.languages.setMonarchTokensProvider('hadron', hadron_language_config.language);
    const layoutContainer = document.querySelector('#root');
    let hub = new Hub(new GoldenLayout(layoutContainer));
    // GoldenLayout
    const layoutConfig = {
        root: {
            type: 'row',
            content: [{
                    type: 'component',
                    componentType: EDITOR_COMPONENT_NAME,
                    title: 'Untitled',
                    isClosable: false, // make not closable until we allow multi-file editing.
                }, {
                    type: 'column',
                    content: [{
                            type: 'component',
                            componentType: HELP_VIEW_COMPONENT_NAME,
                        }, {
                            type: 'component',
                            componentType: POST_VIEW_COMPONENT_NAME,
                        }]
                }],
        },
    };
    function sizeRoot() {
        // TODO: why the fudge factor here?
        const fudgeFactor = 8;
        const width = window.innerWidth - fudgeFactor;
        const height = window.innerHeight - (layoutContainer.offsetTop + fudgeFactor);
        hub.layout.setSize(width, height);
    }
    window.onresize = sizeRoot;
    hub.layout.loadLayout(layoutConfig);
    sizeRoot();
    // menu bar buttons
    function dropDownClick(buttonId) {
        document.getElementById(buttonId + "-dropdown").classList.toggle("show");
    }
    // Add onclick function to show/hide the button dropdown as needed.
    const topButtons = document.getElementsByClassName("top-bar-dropdown-button");
    for (let i = 0; i < topButtons.length; i++) {
        let button = topButtons[i];
        button.onclick = () => dropDownClick(button.id);
    }
    // Add an onclick function to the window to close all dropdowns if the click is outside the dropdown.
    // Close the dropdown menu if the user clicks outside of it
    window.onclick = function (event) {
        let target = event.target;
        if (!target || !target.matches('.top-bar-dropdown-button')) {
            const dropdowns = document.getElementsByClassName("dropdown-content");
            for (let i = 0; i < dropdowns.length; i++) {
                let dd = dropdowns[i];
                if (dd.classList.contains('show')) {
                    dd.classList.remove('show');
                }
            }
            const dropdownsRight = document.getElementsByClassName("dropdown-content-right");
            for (let i = 0; i < dropdownsRight.length; i++) {
                let dd = dropdownsRight[i];
                if (dd.classList.contains('show')) {
                    dd.classList.remove('show');
                }
            }
        }
    };
}
$(start);
