import { HELP_VIEW_COMPONENT_NAME, EDITOR_COMPONENT_NAME, POST_VIEW_COMPONENT_NAME } from './component.interfaces.js';
import { HelpView } from './help-view.js';
import { Editor } from './editor.js';
import { PostView } from './post-view.js';
/**
 * Serves as a Controller between the Hadron Models and the Editor Views.
 *
 * Keeps a map of the relationships between open editors and Hadron text models, and
 * coordinates connecting those two. Binds the layout to the component creation
 * methods. Owns the GoldenLayout object.
 */
export class Hub {
    layout;
    editors;
    constructor(layout) {
        this.layout = layout;
        this.editors = [];
        layout.registerComponentFactoryFunction(HELP_VIEW_COMPONENT_NAME, (container, _itemConfig) => this.createHelpViewComponent(container));
        layout.registerComponentFactoryFunction(EDITOR_COMPONENT_NAME, (container, _itemConfig) => this.createEditorComponent(container));
        layout.registerComponentFactoryFunction(POST_VIEW_COMPONENT_NAME, (container, _itemConfig) => this.createPostViewComponent(container));
    }
    createHelpViewComponent(container) {
        new HelpView(this, container);
    }
    createEditorComponent(container) {
        const editor = new Editor(this, container);
        this.editors.push(editor);
    }
    createPostViewComponent(container) {
        new PostView(this, container);
    }
}
