import _ from 'underscore';
import * as monaco from 'monaco-editor';
import { Pane } from './pane.js';
export class Editor extends Pane {
    editor;
    constructor(hub, container) {
        super(hub, container);
        this.editor = monaco.editor.create(this.container.element, {
            language: 'hadron'
        });
        this.init();
        // Set the intial size of the editor inside the tab or it will size to 0x0.
        this.resize();
    }
    registerCallbacks() {
        // Add cmd+enter keybinding.
        this.editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, () => {
            let selection = this.editor.getSelection();
            console.log(selection);
        });
    }
    resize() {
        _.defer(() => {
            const width = this.container.width;
            const height = this.container.height;
            this.editor.layout({
                width: width,
                height: height
            });
        });
    }
    close() {
        this.editor.dispose();
    }
}
