export const conf = {
    autoClosingPairs: [
        { open: '{', close: '}' },
        { open: '[', close: ']' },
        { open: '(', close: ')' },
        { open: '"', close: '"' },
        { open: "'", close: "'" }
    ],
    brackets: [
        ['{', '}'],
        ['(', ')'],
        ['[', ']']
    ],
    comments: {
        lineComment: '//',
        blockComment: ['/*', '*/']
    },
    surroundingPairs: [
        { open: '{', close: '}' },
        { open: '[', close: ']' },
        { open: '(', close: ')' },
        { open: '"', close: '"' },
        { open: "'", close: "'" }
    ]
};
export const language = {
    // Set defaultToken to invalid to see what you do not tokenize yet
    defaultToken: 'invalid',
    unicode: true,
    brackets: [
        { token: 'delimiter.bracket', open: '{', close: '}' },
        { token: 'delimiter.array', open: '[', close: ']' },
        { token: 'delimiter.parenthesis', open: '(', close: ')' }
    ],
    keywords: [
        'arg', 'classvar', 'const', 'false', 'inf', 'nil', 'pi', 'true', 'var', 'this'
    ],
    // The main tokenizer for our languages
    tokenizer: {
        root: [
            // strings first, so they don't get parsed as identifiers or keywords, etc.
            [/"/, { token: 'string.quote', next: '@strings' }],
            // labels next, so they also don't get mistaken for identifiers
            [/[a-z][\w_\d]*:/, 'tag'],
            // identifiers and keywords
            [/[a-z][\w_\d]*/, {
                    cases: {
                        '@keywords': 'keyword',
                        '@default': 'identifier'
                    }
                }],
            [/[A-Z][\w_\d]*/, 'type.identifier'], // to show class names nicely
            // primitives - TODO: need to style this so they pop.
            [/_[\w_\d]+/, 'predefined'],
            // globals
            [/~[\w_\d]+/, 'entity'],
            // whitespace
            { include: '@whitespace' },
            // delimiters and operators
            [/[{}()\[\]]/, '@brackets'],
            [/[-+=<>?/!%&*|@]+/, 'operators'],
            // numbers
            [/\d+r[\w\d]+[.][\w\d]+/, 'number.float'], // radix float
            [/\d*\.\d+([eE][\-+]?\d+)?/, 'number.float'],
            [/\d+r[\w\d]*/, 'number'], // radix integer
            [/0[xX][0-9a-fA-F]+/, 'number.hex'],
            [/\d+(b{1,4}|s{1,4}|b\d+|s\d+)?/, 'number'],
            // delimiter: after number because of .\d floats
            [/[\^:`#_;,.]/, 'delimiter'],
            // symbols
            [/'/, { token: 'constant.quote', next: '@symbols' }],
            [/\\[\w_0-9]*/, 'constant']
        ],
        comment: [
            [/[^\/*]+/, 'comment'],
            [/\/\*/, 'comment', '@push'], // nested comment
            ["\\*/", 'comment', '@pop'],
            [/[\/*]/, 'comment']
        ],
        strings: [
            [/[^\\"]+/, 'string'],
            [/\\./, 'string.escape'],
            [/"/, { token: 'string.quote', next: '@pop' }]
        ],
        symbols: [
            [/[^\\']+/, 'constant'],
            [/\\./, 'constant.escape'],
            [/'/, { token: 'constant.quote', next: '@pop' }]
        ],
        whitespace: [
            [/[ \t\r\n]+/, 'white'],
            [/\/\*/, 'comment', '@comment'],
            [/\/\/.*$/, 'comment'],
        ],
    }
};
