import * as hadron from './hadron-sclang/hadron_sclang.js';
import { Pane } from './pane.js';
export class PostView extends Pane {
    constructor(hub, container) {
        super(hub, container);
        let div = document.createElement('div');
        div.id = "post";
        this.container.element.append(div);
        hadron.register_diags_callback((level, messages) => {
            // create a new div with the diagnostics
            let div = document.createElement('div');
            // todo: style this class
            div.className = `hadron-diagnostic-${level}`;
            messages.forEach((msg) => {
                let p = document.createElement('p');
                p.innerHTML = msg.body;
                div.appendChild(p);
            });
            document.getElementById('post')?.appendChild(div);
        });
        this.init();
    }
    resize() { }
    close() { }
}
