import * as hadron from './hadron-web/hadron_web.js';
import { Pane } from './pane.js';
export class HelpView extends Pane {
    constructor(hub, container) {
        super(hub, container);
        var div = document.createElement('div');
        div.id = "help";
        div.innerHTML = `
    <h2>hadron ${hadron.get_hadron_version()}
      <a href="https://git.sr.ht/~lnihlen/hadron/commit/${hadron.get_hadron_git_sha()}">
        ${hadron.get_hadron_git_branch()}:${hadron.get_hadron_git_sha_abbrev()}
      </a>
      <a href="https://hadron.run"><img src="/favicon-32x32.png" class="headerimage"/></a>
    </h2>
    <p>Welcome to the Hadron SuperCollider Web. Hadron is an implementation of the
       SuperCollider software, written by
       <a href="https://nihlen.ca">Lucile Rose Nihlen</a>.</p>
    
    <p><strong>Note:</strong> this software is under active development and is
      not yet functional. You can follow development status on
      <a href="https://sr.ht/~lnihlen/hadron/">sourcehut</a> or join the
      <a href="https://discord.gg/YtEJveDd">Discord</a> server.</p>`;
        this.container.element.append(div);
        this.init();
    }
    resize() { }
    close() { }
}
